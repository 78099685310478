import { Button, Color, SubtitleXS, TextXS } from '@formify/frontend-components';
import { LoginFormContainer, LoginFormGridContainer, LoginPageContainer } from './LoginPageComponent.styled';
import { UNEXPECTED_ERROR } from '../../Checkout/UserInfoSteps/steps/components/PaymentForm/contants';
import { showToastr } from '../../../store/toastr/actions';
import { getServices } from '../../../store/dependency/selectors';
import { useStore } from 'react-redux';
import { RootState } from '../../../store';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { FirebaseError } from 'firebase/app';
import { TextInputWithMessage } from '../../TextInputWithMessage/TextInputWithMessage';
import { emailValidator } from '../../ContextMenu/components/SaveProject/validators/saveProjectFormValidator';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { fetchOrderData } from '../../../store/project/actions';
import styled from 'styled-components';
import { Tr, useTr } from '../../Translations/Tr';
import { useEnv } from '../../../hooks/useEnv';

const AlignCenter = styled.div`
    text-align: center;
`;

const registerFormValidator = Yup.object().shape({
    email: emailValidator,
    password: Yup.string().required('Password is required'),
});

export const LoginPageComponent = () => (
    <LoginPageContainer>
        <LoginFormContainer>
            <LoginFormComponent />
        </LoginFormContainer>
    </LoginPageContainer>
);

export const LoginFormComponent = () => {
    const { dispatch, getState } = useStore<RootState, any>();
    const [isPending, setIsPending] = useState(false);
    const { push, pathname } = useRouter();
    const defaultLoginRedirect = useEnv('DEFAULT_LOGIN_REDIRECT');

    const methods = useForm<{
        email: string;
        password: string;
    }>({
        resolver: yupResolver(registerFormValidator),
    });

    const { register, handleSubmit, setError, formState } = methods;
    const { errors } = formState;

    const signInWithEmail = async (email: string, password: string) => {
        try {
            const { auth } = getServices(getState());

            setIsPending(true);
            await auth.signInWithEmail(email, password);
            dispatch(fetchOrderData());
            if (pathname === '/login') {
                defaultLoginRedirect && push(defaultLoginRedirect);
            }
        } catch (error) {
            if (error instanceof FirebaseError) {
                if (
                    error.code === 'auth/user-not-found' ||
                    error.code === 'auth/missing-email' ||
                    error.code === 'auth/wrong-password'
                ) {
                    setError('email', {
                        message: 'invalid credentials',
                    });

                    setError('password', {
                        message: 'invalid credentials',
                    });
                    dispatch(
                        showToastr({
                            description: error.message,
                        }),
                    );
                }

                return;
            }
            dispatch(
                showToastr({
                    description: UNEXPECTED_ERROR,
                }),
            );
        } finally {
            setIsPending(false);
        }
    };

    return (
        <form
            onSubmit={handleSubmit((data) => {
                signInWithEmail(data.email, data.password);
            })}
        >
            <FormProvider {...methods}>
                <LoginFormGridContainer>
                    <div>
                        <Tr labelKey="login_title" defaultValue="LOGIN" />
                    </div>
                    <TextXS>
                        <Tr
                            labelKey="login_description"
                            defaultValue="Please login or register to access the configurator"
                        />
                    </TextXS>
                    <div>
                        <TextInputWithMessage
                            responsiveWidth
                            label={useTr('form_email_field_label', 'Email address')}
                            {...register('email')}
                            error={Boolean(errors.email)}
                            message={errors.email?.message}
                        ></TextInputWithMessage>
                    </div>
                    <div>
                        <TextInputWithMessage
                            responsiveWidth
                            label={useTr('form_password_field_label', 'Password')}
                            type="password"
                            {...register('password')}
                            error={Boolean(errors.password)}
                            message={errors.password?.message}
                        ></TextInputWithMessage>
                    </div>
                    <SubtitleXS className="text-right">
                        <Link href="/reset-password" passHref legacyBehavior>
                            <span className="cursor-pointer">
                                <Tr labelKey="reset_password_link" defaultValue="Reset Password" />
                            </span>
                        </Link>
                    </SubtitleXS>
                    <Button dataTestId="login_button" full isLoading={isPending}>
                        <Tr labelKey="login_button" defaultValue="Login" />
                    </Button>

                    <AlignCenter>
                        <TextXS color={Color.Secondary}>
                            <Tr labelKey="description_between_login_and_register" defaultValue="or" />
                        </TextXS>
                    </AlignCenter>

                    <Link href="/register" passHref legacyBehavior>
                        <Button full disabled={isPending} variant="secondary">
                            <Tr labelKey="register_button" defaultValue="Register" />
                        </Button>
                    </Link>
                </LoginFormGridContainer>
            </FormProvider>
        </form>
    );
};
