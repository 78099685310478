import Head from 'next/head';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { setUpdateOrderByAdmin } from '../../store/project/actions';
import { useDispatch } from 'react-redux';
import { getIsAddingProject } from '../../store/project/selectors';
import { useSelector } from 'react-redux';
import { useRouterParams } from '../../hooks/useRouterParams';
import { ConfiguratorQueryParams } from '../../constants/router';
import { Crisp } from '../../components/Crisp/Crisp';
import { Editor3D } from './Editor3D';
import { getIsHeaderVisible } from '../../store/layout/selectors';
import { Header } from '../Header/Header';
import { setHeaderVisibility } from '../../store/layout/actions';
import { ContentForVerifiedUsers } from '../Login/ContentForVerifiedUsers';
import { useChannel } from '../Channel/ChannelProvider';
import { useLanguage } from '../Translations/LanguageProvider';

const BlockScreenOverlayStyled = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: 'transparent';
    pointer-events: 'none';
`;

interface EditorProps {
    dragAndDropIsEnable?: boolean;
}

export const EditorPage: React.FC<EditorProps> = ({ dragAndDropIsEnable = false }) => {
    const { name } = useChannel();
    const { t } = useLanguage();
    const isAddingProductToCart = useSelector(getIsAddingProject);
    const dispatch = useDispatch();
    const { params, setParams } = useRouterParams<ConfiguratorQueryParams>();
    const isHeaderVisible = useSelector(getIsHeaderVisible);

    useEffect(() => {
        if (params.token && params.orderItemId) {
            dispatch(
                setUpdateOrderByAdmin({
                    token: params.token,
                    orderItemId: params.orderItemId,
                }),
            );

            setParams({
                token: '',
                orderItemId: '',
            });
        }
    }, [dispatch, params, setParams]);

    return (
        <>
            {isAddingProductToCart && <BlockScreenOverlayStyled />}
            <Head>
                <title property="og:title">
                    {t('pageTitle.wardrobeEditor', '${name}', {
                        name,
                    })}
                </title>
                <meta name="description" property="og:description" content="Design yours wardrobe" />

                <style>{`
                    html,
                    body,
                    div#__next,
                    div#__next > div,
                    .framer-motion {
                        height: 100%;
                        overflow: hidden;
                    }
                `}</style>
            </Head>
            <Crisp showOnMobile={false} />
            <ContentForVerifiedUsers>
                <Editor3D dragAndDropIsEnable={dragAndDropIsEnable}>
                    <div className={`${params.option === 'summary' ? 'hidden' : 'flex'} md:flex`}>
                        <Header
                            isVisible={isHeaderVisible}
                            handleVisbilityChanged={() => dispatch(setHeaderVisibility(!isHeaderVisible))}
                        />
                    </div>
                </Editor3D>
            </ContentForVerifiedUsers>
        </>
    );
};
