import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { RootState } from '../../store';
import { getServices } from '../../store/dependency/selectors';

export function useUserState() {
    const store = useStore<RootState, any>();
    const [userState, setUserState] = useState<'login' | 'not-login' | 'not-verify' | 'loading'>('loading');

    useEffect(() => {
        const state = store.getState();
        const { auth } = getServices(state);

        auth.onAuthStateChanged((user) => {
            if (user === null) {
                setUserState('not-login');

                return;
            }
            auth.getSyliusToken().then((token) => {
                setUserState(token === null ? 'not-verify' : 'login');
            });
        });
    }, [store]);

    return userState;
}
