import { Loader } from '../atoms/Loader/Loader';
import { NeedGrantPageComponent } from './NeedGrantPageComponent/NeedGrantPageComponent';
import { LoginPageComponent } from './LoginPageComponent/LoginPageComponent';
import { Header } from '../Header/Header';
import { useUserState } from './useUserState';

interface ContentForVerifiedUsersProps {
    children: React.ReactNode;
}

export const ContentForVerifiedUsers = ({ children }: ContentForVerifiedUsersProps) => {
    const userState = useUserState();

    if (userState === 'loading') {
        return <Loader isPending />;
    }

    if (userState === 'login') {
        return <>{children}</>;
    }

    if (userState === 'not-verify') {
        return (
            <>
                <Header isVisible position="absolute" />
                <NeedGrantPageComponent />
            </>
        );
    }

    if (userState === 'not-login') {
        return (
            <>
                <Header isVisible position="absolute" />
                <LoginPageComponent />
            </>
        );
    }

    return null;
};
