import React, { FC } from 'react';
import { TextInput, TextInputProps } from '../atoms/TextInput/TextInput';
import { Message } from '../atoms/Message/Message';
import { useLanguage } from '../Translations/LanguageProvider';

interface TextInputWithMessageProps extends TextInputProps {
    message?: string;
}

export const TextInputWithMessage: FC<TextInputWithMessageProps> = ({ message, ...props }) => {
    const { t } = useLanguage();

    return (
        <>
            <TextInput {...props} label={props.label ? t(props.label) : undefined} />
            {message && <Message error={props.error} message={t(message, message)} />}
        </>
    );
};
