import type { NextPage } from 'next';
import React, { useCallback, useEffect, useState } from 'react';
import { setAllProducts } from '../store/products/actions';
import { setBlockItems } from '../store/project/actions';
import { fetchProducts } from '../services/products/products';
import { fetchConfigurations } from '../services/configurations/configurations';
import { getBlockItems, getDefaultProjectSize, getProject } from '../services/project/project';
import { fetchWardrobePrice, setAllConfigurations } from '../store/configurations/actions';
import {
    assignConfigurationToColumn,
    setColumns,
    setLazyWidth,
    setLed,
    setVisibility,
    setWoodColor,
} from '../store/wardrobe/actions';
import { setAllMedia } from '../store/media/actions';
import { fetchMedia } from '../services/media/media';
import { formatProducts } from '../services/nodes/wardrobe/wardrobe';
import { WardrobeBuilderFromProject } from '../services/nodes/wardrobe/builders/WardrobeBuilderFromProject';
import { Action } from 'redux';
import { ANIMATIONS } from '../constants/animations';
import { EditorPage } from '../components/EditorPage/EditorPage';
import { AllDecorators } from '../decorators/forStaticPages/AllDecorators';
import { getPublicEnv } from '../services/env/env';
import { useRouterParams } from '../hooks/useRouterParams';
import { Loader } from '../components/atoms/Loader/Loader';
import { ConfiguratorQueryParams } from '../constants/router';
import { useDispatch } from 'react-redux';
import { useLockEffectLazy } from '../hooks/useLockEffect';
import { DOORS_SELECTION } from '../constants/products/DoorSelection.enum';

const Editor: NextPage<{}> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dragAndDropIsEnable, setDragAndDropIsEnable] = useState<boolean | null>(null);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        getPublicEnv('FEAT_FLAG_DRAG').then((flag) => {
            setDragAndDropIsEnable(flag === '1');
        });
    });

    const { params } = useRouterParams<ConfiguratorQueryParams>();

    const init = useCallback(async () => {
        const projectId = params?.projectId || 'default';

        const [products, configurations, blockItems, media, project, wardrobeSize] = await Promise.all([
            fetchProducts(),
            fetchConfigurations(),
            getBlockItems(),
            fetchMedia(),
            getProject(projectId).catch(() => null),
            getDefaultProjectSize(),
        ]);

        const configurationsId = Object.keys(configurations);
        const formattedProducts = formatProducts(products);

        const initialReduxActions: Action[] = [
            setAllMedia(media),
            setAllProducts(products),
            setAllConfigurations(configurations),
            setBlockItems(blockItems),
            ...configurationsId
                .filter((id) => configurations[id]?.isEnabled)
                .map((id, index) =>
                    assignConfigurationToColumn({
                        configurationId: id,
                        columnIndex: index,
                    }),
                ),
        ];

        if (formattedProducts === null) {
            return initialReduxActions;
        }

        if (project !== null) {
            const builder = new WardrobeBuilderFromProject(formattedProducts);

            initialReduxActions.push(...builder.getActionForStore(project));

            if (projectId === 'default') {
                initialReduxActions.push(setVisibility(3));
                initialReduxActions.push(setLazyWidth(wardrobeSize));
            }

            initialReduxActions.push(
                fetchWardrobePrice.fulfilled(
                    {
                        unitPriceWithChildItems: project.mainItem.unitPriceWithChildItems,
                    },
                    '',
                    {
                        currencyCode: 'EUR',
                    },
                ),
            );
        } else {
            const firstEnableLayout = configurationsId.find((id) => configurations[id]?.isEnabled) ?? null;

            initialReduxActions.push(setVisibility(1));
            initialReduxActions.push(setWoodColor('BLACK_WOOD'));
            initialReduxActions.push(setLed(false));
            initialReduxActions.push(
                setColumns([
                    {
                        color: 'BLACK_WOOD',
                        configurationId: firstEnableLayout,
                        door: null,
                        data: {
                            width: 1000,
                            height: 2100,
                            depth: 600,
                        },
                        doorHandlePosition: 'LEFT',
                        doorHandleType: 'door_handle_gold',
                        shelveType: 'shelve',
                        doorSelection: DOORS_SELECTION.NOT_SELECTED,
                        elements: [],
                        hidden: false,
                        wall: 'A',
                    },
                ]),
            );
        }

        return initialReduxActions;
    }, [params?.projectId]);

    useLockEffectLazy(init, (initialReduxActions) => {
        initialReduxActions.forEach((action) => dispatch(action));
        setTimeout(() => {
            setIsLoading(false);
        }, 1);
    });

    if (dragAndDropIsEnable === null || isLoading) {
        return <Loader isPending />;
    }

    return <AllDecorators>{() => <EditorPage dragAndDropIsEnable={dragAndDropIsEnable} />}</AllDecorators>;
};

export const getStaticProps = async () => ({
    props: {
        initialReduxActions: [],
        animation: ANIMATIONS.opacity,
    },
});

export default Editor;
