import { Button, TextXS } from '@formify/frontend-components';
import {
    LoginPageContainer,
    LoginFormContainer,
    LoginFormGridContainer,
} from '../LoginPageComponent/LoginPageComponent.styled';
import { Tr } from '../../Translations/Tr';
import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
import { RootState } from '../../../store';
import { getServices } from '../../../store/dependency/selectors';

export const NeedGrantPageComponent = () => (
    <LoginPageContainer>
        <LoginFormContainer>
            <NeedGrantInfo />
        </LoginFormContainer>
    </LoginPageContainer>
);

export const NeedGrantInfo = () => {
    const router = useRouter();
    const { getState } = useStore<RootState, any>();

    const signOut = async () => {
        const { auth } = getServices(getState());

        await auth.signOut();

        router.push('/login');
    };

    return (
        <LoginFormGridContainer>
            <div className="flex justify-center">
                <svg width="37" height="43" viewBox="0 0 37 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3944 0.544443C18.8314 0.262916 18.1686 0.262916 17.6056 0.544443L17.6002 0.547121L1.60557 8.54444C0.928005 8.88323 0.5 9.57575 0.5 10.3333C0.5 32.3768 11.6046 41.3377 18.0149 42.9403C18.3839 43.0325 18.7716 43.0176 19.1325 42.8973C26.3355 40.4963 30.6584 34.3035 33.1602 27.9679C35.671 21.6096 36.5 14.7515 36.5 10.3333C36.5 9.57575 36.072 8.88323 35.3944 8.54444L19.3944 0.544443ZM4.51232 11.5632L18.5 4.56937L32.4779 11.5583C32.3374 15.5759 31.5086 21.2597 29.4398 26.4987C27.2205 32.1189 23.7154 36.8981 18.4373 38.8997C14.0088 37.4321 4.89321 30.4212 4.51232 11.5632ZM27.817 18.5051C28.6483 17.7778 28.7325 16.5143 28.0052 15.683C27.2778 14.8517 26.0143 14.7675 25.183 15.4948L15.9245 23.596L11.9142 19.5858C11.1332 18.8047 9.86683 18.8047 9.08579 19.5858C8.30474 20.3668 8.30474 21.6332 9.08579 22.4142L14.4191 27.7475C15.1637 28.4921 16.3579 28.5318 17.1503 27.8385L27.817 18.5051Z"
                        fill="#0D8F6C"
                    />
                </svg>
            </div>
            <div>
                <Tr labelKey="waiting_confirmation" defaultValue="WAITING CONFIRMATION" />
            </div>
            <TextXS>
                <Tr
                    labelKey="waiting_confirmation_description"
                    defaultValue="Thank you for registering. We will review your information and send you an update email"
                />
            </TextXS>

            <Button variant="primary" full onClick={signOut}>
                <Tr labelKey="go_to_login_button" defaultValue="GO TO LOGIN" />
            </Button>
        </LoginFormGridContainer>
    );
};
